import { Injectable } from '@angular/core';
import { EncrDecrService } from './encr-decr.service';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  constructor(
    private encrDecrService: EncrDecrService
  ) { }

  keyCode: string = '$@lut3Fr0ntl!n3r';

  clear() {
    sessionStorage.clear();
  }

  public saveFisherfolkProfileId(id: any){
    sessionStorage.setItem('FISHERFOLKPROFILE_ID', id);
  }

  public getFisherfolkProfileId(){
    return Number(sessionStorage.getItem('FISHERFOLKPROFILE_ID'));
  }

  public saveAddressId(id: any){
    sessionStorage.setItem('ADDRESS_ID', id);
  }

  public getAddressId(){
    return Number(sessionStorage.getItem('ADDRESS_ID'));
  }

  public saveIsNewFisherfolkProfile(isNew: any){
    sessionStorage.setItem('IS_NEW_FISHERFOLKPROFILE', isNew);
  }

  public getIsNewFisherfolkProfile(){
    return sessionStorage.getItem('IS_NEW_FISHERFOLKPROFILE');
  }

  public saveIsAccountLogin(isLogin: any){
    // sessionStorage.setItem('IS_ACCOUNT_LOGIN', isLogin);
    sessionStorage.setItem('IS_ACCOUNT_LOGIN', this.encrDecrService.set(this.keyCode, isLogin));
  }

  public getIsAccountLogin(){
    // return sessionStorage.getItem('IS_ACCOUNT_LOGIN');
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('IS_ACCOUNT_LOGIN'));
  }

  public saveIsSaveOrUpdate(data: any){
    // sessionStorage.setItem('SAVE_OR_UPDATE', data);
    sessionStorage.setItem('SAVE_OR_UPDATE', this.encrDecrService.set(this.keyCode, data));
  }

  public getIsSaveOrUpdate(){
    // return sessionStorage.getItem('SAVE_OR_UPDATE');
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('SAVE_OR_UPDATE'));
  }

  public saveUserAccountName(username: any) {
    sessionStorage.setItem('USER_ACCOUNT', username);
  }

  public getUserAccountName() {
    return sessionStorage.getItem('USER_ACCOUNT');
  }

  public saveUserRole(role: string) {
    sessionStorage.setItem('USER_ROLE', this.encrDecrService.set(this.keyCode, role));
  }

  public getUserRole() {
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('USER_ROLE'));
  }

  public saveUserProfile(profile: string) {
    sessionStorage.setItem('USER_PROFILE_ID', this.encrDecrService.set(this.keyCode, profile));
  }

  public getUserProfile() {
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('USER_PROFILE_ID'));
  }

  public saveUserRegionCode(regionCode: any){
    sessionStorage.setItem('REGION_CODE', this.encrDecrService.set(this.keyCode, regionCode));
  }

  public getUserRegionCode(){
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('REGION_CODE'));
  }

  public saveUserRegionName(regionName: any){
    sessionStorage.setItem('REGION_NAME', this.encrDecrService.set(this.keyCode, regionName));
  }

  public getUserRegionName(){
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('REGION_NAME'));
  }

  public saveUserProvinceName(provinceName: any){
    sessionStorage.setItem('PROVINCE_NAME', this.encrDecrService.set(this.keyCode, provinceName));
  }

  public getUserProvinceName(){
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('PROVINCE_NAME'));
  }

  public saveUserProvinceCode(provinceCode: any){
    sessionStorage.setItem('PROVINCE_CODE', this.encrDecrService.set(this.keyCode, provinceCode));
  }

  public getUserProvinceCode(){
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('PROVINCE_CODE'));
  }

  public saveUserCityMunicipalityCode(cityMunicipalityCode: any){
    sessionStorage.setItem('CITY_MUNICIPALITY_CODE', this.encrDecrService.set(this.keyCode, cityMunicipalityCode));
  }

  public getUserCityMunicipalityCode(){
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('CITY_MUNICIPALITY_CODE'));
  }

  public saveUserCityMunicipalityName(cityMunicipalityName: any){
    sessionStorage.setItem('CITY_MUNICIPALITY_NAME', this.encrDecrService.set(this.keyCode, cityMunicipalityName));
  }

  public getUserCityMunicipalityName(){
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('CITY_MUNICIPALITY_NAME'));
  }

  public saveUserRoleLevel(roleLevel: any) {
    sessionStorage.setItem('USER_ROLE_LEVEL', this.encrDecrService.set(this.keyCode, roleLevel));
  }

  public getUserRoleLevel() {
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('USER_ROLE_LEVEL'));
  }

  public saveIsFirstLogin(isFirstLogin: any){
    // sessionStorage.setItem('IS_FIRST_LOGIN', isFirstLogin);
    sessionStorage.setItem('IS_FIRST_LOGIN', this.encrDecrService.set(this.keyCode, isFirstLogin));
  }

  public getIsFirstLogin(){
    // return sessionStorage.getItem('IS_FIRST_LOGIN');
    return this.encrDecrService.get(this.keyCode, sessionStorage.getItem('IS_FIRST_LOGIN'));
  }
}
