import { DataStorageService } from './../data.storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { TokenStorage } from './token.storage';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    public router: Router, 
    private token: TokenStorage,
    private dataStorageService: DataStorageService) { }

  canActivate() {

    let token = this.token.getToken();

    if (token != undefined || token != null) {
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: null } });
    return false;
  }

  canActivateChild(): boolean {
    let loggedInUserRole = this.dataStorageService.getUserRole();
    if (loggedInUserRole === 'ROLE_MAYOR' || 
    loggedInUserRole === 'ROLE_VICE_MAYOR' || 
    loggedInUserRole === 'ROLE_CMAO' || 
    loggedInUserRole === 'ROLE_CM_FARMC' ||
    loggedInUserRole === 'ROLE_BARANGAY_CHAIRMAN' ||
    loggedInUserRole === 'ROLE_LGU_ENCODER' ||
    loggedInUserRole === 'ROLE_LGU_SUPERVISOR' ||
    loggedInUserRole === 'ROLE_PROVINCIAL_ADMINISTRATOR' ||
    loggedInUserRole === 'ROLE_REGIONAL_ENCODER' || 
    loggedInUserRole === 'ROLE_REGIONAL_SUPERVISOR' ||
    loggedInUserRole === 'ROLE_CENTRAL_ADMINISTRATOR' ||
    loggedInUserRole === 'ROLE_SUPER_ADMIN' ||
    loggedInUserRole === 'ROLE_SAAD_USER'
    ) {
      return true;
    } else {

      this.router.navigate(['/access-denied']);
      return false;
    }
  }



}
