import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbDialogModule, NbThemeModule, NbSidebarModule, NbMenuModule, NbWindowModule, NbToastrModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from './shared/utils/angular-material.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AuthHttpInterceptorService } from './core/services/authentication/auth-http-interceptor.service';
import { NumberFormatPipe } from './shared/utils/number.pipe';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    NumberFormatPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    NbDialogModule.forRoot(),
    NbThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    SharedModule.forRoot(),
    NbMenuModule.forRoot(),
    NbWindowModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    NbToastrModule.forRoot()
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptorService, multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
