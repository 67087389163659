import { DataStorageService } from './../data.storage.service';
import * as jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from './token.storage';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { AccountService } from '../account.service';
import { UserAccount } from '../../models/user-account.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class LoginService {

  API_URL = environment.API_GATEWAY;
  ENDPOINT = 'auth/authentication/';

  constructor(
    public http: HttpClient,
    private tokenStorage: TokenStorage,
    private acountService: AccountService,
    private dataStorageService: DataStorageService
  ) { }

  login(resource: string, userAccount: UserAccount) {
    return this.http.post(`${this.API_URL}${this.ENDPOINT}${resource}`, userAccount).pipe(
      map(
        (res: any) => {

          this.tokenStorage.saveToken(res.accessToken);
          const decodedToken = jwt_decode(res.accessToken);
          const role = decodedToken.user_role;
          const userProfileId = decodedToken.user_profile_id;
          const userRoleLevel = decodedToken.user_role_level;
          const isFirstLogIn = decodedToken.is_first_login;
          this.dataStorageService.saveUserRole(role);
          this.dataStorageService.saveUserProfile(userProfileId);
          this.dataStorageService.saveUserAccountName(userAccount.username);
          this.dataStorageService.saveUserRoleLevel(userRoleLevel);
          this.dataStorageService.saveIsFirstLogin(isFirstLogIn);

          // this.acountService.findById('user-profiles', this.dataStorageService.getUserProfile()).subscribe((data: any) => {
          //   this.dataStorageService.saveUserRegionCode(data.regionCode);
          //   this.dataStorageService.saveUserRegionName(data.regionName);
          //   this.dataStorageService.saveUserProvinceCode(data.provinceCode);
          //   this.dataStorageService.saveUserProvinceName(data.provinceName);
          //   this.dataStorageService.saveUserCityMunicipalityCode(data.cityMunicipalityCode);
          //   this.dataStorageService.saveUserCityMunicipalityName(data.cityMunicipalityName);

          // });

        }
      )
    );
  }

  logout(resource: string, token: any): Observable<any> {
    return this.http.post(`${this.API_URL}${this.ENDPOINT}${resource}${token}`, token);
  }
}
