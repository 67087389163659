import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/services/authentication/auth-guard.service';
import { AccessDeniedComponent } from './shared/access-denied/access-denied/access-denied.component';


const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    loadChildren: () => import('./modules/pages/pages.module')
      .then(m => m.PagesModule)
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/modules/pages/login/login.module')
      .then(m => m.LoginModule)
  },
  {
    path: 'log-out',
    loadChildren: () => import('src/app/modules/pages/login/login.module')
      .then(m => m.LoginModule),
      data: {data: 'logout'}
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
