import { DataStorageService } from './../data.storage.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { TokenStorage } from './token.storage';

@Injectable({
    providedIn: 'root'
  })
  export class AuthHttpInterceptorService implements HttpInterceptor{

    constructor(
      private tokenStorage: TokenStorage,
      private dataStorageService: DataStorageService) { }

    intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler) {
      if(this.dataStorageService.getUserAccountName() && this.tokenStorage.getToken()){
        httpRequest = httpRequest.clone({
          setHeaders: {
            Authorization: `Bearer ${this.tokenStorage.getToken()}`
          }
        });
      }

      return httpHandler.handle(httpRequest);
    }
  }
