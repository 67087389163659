import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import {
  NbLayoutModule, NbMenuModule, NbActionsModule, NbSidebarModule, NbButtonModule, NbSelectModule,
  NbIconModule, NbThemeModule, NbUserModule, NbContextMenuModule, NbSearchModule, NbTooltipModule
} from '@nebular/theme';
import { DEFAULT_THEME } from './styles/theme.default';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AccessDeniedComponent } from './access-denied/access-denied/access-denied.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbTooltipModule
];

const COMPONENTS = [
  FooterComponent,
  HeaderComponent,
  LayoutComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    ...NB_MODULES
  ],
  exports: [
    CommonModule,
    ...COMPONENTS,
    ...NB_MODULES
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default'
          },
          [DEFAULT_THEME]
        ).providers
      ]
    } as ModuleWithProviders;
  }
}
