import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { TokenStorage } from './authentication/token.storage';

@Injectable({
    providedIn: 'root'
  })
  export class  AccountService {

    constructor(private http: HttpClient) {}

    API = env.API_GATEWAY;
    SERVICE_NAME = 'account/'

    save(endpoint: any, object: object): Observable<any> {
      return this.http.post(`${this.API}${this.SERVICE_NAME}${endpoint}`, object);
    }

    update(endpoint: any, object: object): Observable<any> {
      return this.http.put(`${this.API}${this.SERVICE_NAME}${endpoint}`, object);
    }

    findByUsername(endpoint: any, username : string): Observable<any> {
      return this.http.get(`${this.API}${this.SERVICE_NAME}${endpoint}/username/${username}`);
    }

    findById(endpoint: any,  id: number): Observable<any> {
      return this.http.get(`${this.API}${this.SERVICE_NAME}${endpoint}/id/${id}`);
    }

    findByParam(endpoint: any, param: any): Observable<any> {
      return this.http.get(`${this.API}${this.SERVICE_NAME}${endpoint}/${param}`);
    }

    findAll(endpoint: any): Observable<any> {
      return this.http.get(`${this.API}${this.SERVICE_NAME}${endpoint}`);
    }

    validateUsername(endpoint: any, username : string): Observable<any> {
      return this.http.get(`${this.API}${this.SERVICE_NAME}${endpoint}/validate/username/${username}`);
    }



  }
