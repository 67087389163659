import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {
  MatSelectModule,
  MatOptionModule,
  MatFormFieldModule,
  MatInputModule,
  MatStepperModule,
  MatTabsModule,
  MatIconModule,
  MatCardModule,
  MatRadioModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatSortModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

const MATERIAL_MODULES = [
  MatAutocompleteModule,
  MatButtonModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatOptionModule,
  MatFormFieldModule,
  MatInputModule,
  MatStepperModule,
  MatTabsModule,
  MatIconModule,
  MatCardModule,
  MatRadioModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatSortModule,
  FlexLayoutModule
];

const ANGULAR_FORMS = [
  FormsModule,
  ReactiveFormsModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...MATERIAL_MODULES,
    ...ANGULAR_FORMS
  ],
  exports: [
    ...MATERIAL_MODULES,
    ...ANGULAR_FORMS
  ]
})
export class AngularMaterialModule { }
