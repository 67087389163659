import { Component, OnInit } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { TokenStorage } from 'src/app/core/services/authentication/token.storage';
import { LoginService } from 'src/app/core/services/authentication/login.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';
import { DataStorageService } from 'src/app/core/services/data.storage.service';
import { LayoutService } from 'src/app/core/services/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  userProfileId = this.dataStorageService.getUserProfile();
  name: any;

  constructor(
    private tokenStorage: TokenStorage,
    private loginService: LoginService,
    private router: Router,
    private dataStorageService: DataStorageService,
    private accountService: AccountService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
  ) {
    this.getUserInfo();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  logout(): void {

    const useToken = this.tokenStorage.getToken();

    // console.log('Token', useToken);

    this.loginService.logout('log-out/', useToken).subscribe((res: any) => {

    }, (error: any) => {
      // console.log(error)
      this.tokenStorage.signOut();
      this.router.navigateByUrl('/login');
      // console.log("SESSION DESTROY");
    });

  }

  getUserInfo() {
    this.accountService.findByParam('user-accounts/user-profile/id', this.userProfileId).subscribe((userData: any) => {
      this.name = userData.username;
      
    });
  }

  ngOnInit() {
  }

}
