import { DataStorageService } from './../../../core/services/data.storage.service';
import { Component, OnInit } from '@angular/core';
import { TokenStorage } from 'src/app/core/services/authentication/token.storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  constructor(private router: Router) { }

  goToPrevious(): void {
      this.router.navigateByUrl('/pages/dashboard');
  }

  ngOnInit() {
  }

}
